import { TextProps } from 'components/Text/types'
import styled from 'styled-components'
import { space, SpaceProps, typography } from 'styled-system'

const Input = styled.input<SpaceProps & TextProps>`
  background-color: ${({ theme }) => theme.colors.input};
  border: 0;
  border-radius: ${({ theme }) => theme.radii.small};
  color: ${({ theme }) => theme.colors.text};
  display: block;
  width: 100%;
  text-align: left;
  height: ${({ height }) => height || '36px'};
  outline: none;
  padding: 4px 12px;
  font-size: 16px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.textSubtle};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.backgroundDisabled};
    box-shadow: none;
    color: ${({ theme }) => theme.colors.textDisabled};
    cursor: not-allowed;
  }

  ${space}
  ${typography}
`

export default Input
