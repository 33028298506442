import _Big from 'big.js'
import toFormat from 'toformat'

import { Token } from './token'
import BigNumber from 'bignumber.js'
import { getBalanceAmount, getDecimalAmount } from 'utils/formatBalance'

export class TokenAmount {
  token: Token
  _amount: BigNumber

  public get amount() {
    return this.toExact()
  }

  public constructor(token: Token, amount: string | number | BigNumber) {
    this._amount = getDecimalAmount(amount, token.decimals)
    this.token = token
  }

  public toExact(format: object = { groupSeparator: '' }): string {
    return getBalanceAmount(this._amount, this.token.decimals).toString(10)
  }

  public greaterThan(tokenAmount: TokenAmount): boolean {
    return getDecimalAmount(this._amount, this.token.decimals).gt(
      getDecimalAmount(tokenAmount._amount, tokenAmount.token.decimals),
    )
  }

  public equalTo(tokenAmount: TokenAmount): boolean {
    return (
      this.token.address.toLowerCase() === tokenAmount.token.address.toLowerCase() &&
      this.token.symbol.toLowerCase() === tokenAmount.token.symbol.toLowerCase()
    )
  }
}
