import Box from 'components/Box/Box'
import { BoxProps } from 'components/Box/types'
import React from 'react'
import theme from 'theme'

const Page: React.FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box px={['12px', '', '24px']} mx="auto" width="100%" maxWidth={theme.siteWidth} {...props}>
      {children}
    </Box>
  )
}

export default Page
